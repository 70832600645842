import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Bienvenido a Zepeda!';

  constructor(private router: Router) { }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
      this.handleLocalStorageForTodayDate();
    });
  }
  handleLocalStorageForTodayDate() {
    const allowedRoutes = ['/nuevadoc', '/editdoc/', '/documentaciones'];  // Ajusta estas rutas según sea necesario
    const currentRoute = this.router.url;

    if (!allowedRoutes.some(route => currentRoute.startsWith(route))) {
      localStorage.removeItem('todayDate');
    }
  }
}
