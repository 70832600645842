import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TokenStorageService } from '../../shared/JWT/token-storage.service';

@Injectable({
  providedIn: 'root'
})
export class UsuariosService {

  constructor(public tokenStorage: TokenStorageService,private http: HttpClient) { }

  login(user,pass): Observable<any> {
    return this.http.post(this.tokenStorage.getapi()+'Login/authenticate', {
      Username: user,
      Password: pass
    });
  } 
}
