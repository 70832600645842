import {Injectable} from '@angular/core';

const TOKEN_KEY = 'auth-token';
const USER_KEY = 'auth-user';
const PERMISOS_KEY = 'auth-permisos';
const TURNO_ID = 'turno';
const CURRENT_SUC = 'sucursal';

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {

  constructor() {
  }

  signOut() {
    window.sessionStorage.clear();
  }

  public saveToken(token: string) {

    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.setItem(TOKEN_KEY, token);
  }

  public getToken(): string {
    return sessionStorage.getItem(TOKEN_KEY);
  }

  public saveUser(user) {
    window.sessionStorage.removeItem(USER_KEY);
    window.sessionStorage.setItem(USER_KEY, JSON.stringify(user));
  }

  public getUser() {
    return JSON.parse(sessionStorage.getItem(USER_KEY));
  }

  public savePermisos(permisos) {
    window.sessionStorage.removeItem(PERMISOS_KEY);
    window.sessionStorage.setItem(PERMISOS_KEY, JSON.stringify(permisos));
  }

  public getPermisos() {
    return JSON.parse(sessionStorage.getItem(PERMISOS_KEY));
  }

  public saveTurno(turno) {
    window.sessionStorage.removeItem(TURNO_ID);
    window.sessionStorage.setItem(TURNO_ID, turno);
  }

  public getTurno() {
    return (sessionStorage.getItem(TURNO_ID) != '' ? JSON.parse(sessionStorage.getItem(TURNO_ID)) : undefined);
  }

  public saveSuc(sucursal) {
    window.sessionStorage.removeItem(CURRENT_SUC);
    window.sessionStorage.setItem(CURRENT_SUC, sucursal);
  }

  public getSuc() {
    return sessionStorage.getItem(CURRENT_SUC);
  }

  public getapi() {
    return 'https://ws.aquiconzepedalogistic.com/';
    // return 'http://localhost:44393/';
  }

  public getCreditApi() {
    return 'https://credito.aquiconzepedalogistic.com/api';
    // return 'http://billar.test/api';
  }
}
