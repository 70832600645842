import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AdminComponent} from './layout/admin/admin.component';
import {AuthComponent} from './layout/auth/auth.component';

const routes: Routes = [
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
      },
      {
        path: 'login',
        loadChildren: () => import('./pages/auth/login/basic-login/basic-login.module').then(m => m.BasicLoginModule)
      },
      {
        path: 'registration',
        loadChildren: () => import('./pages/auth/registration/basic-reg/basic-reg.module').then(m => m.BasicRegModule)
      },
      {
        path: 'forgot',
        loadChildren: () => import('./pages/auth/forgot/forgot.module').then(m => m.ForgotModule)
      },
      {
        path: 'recover',
        loadChildren: () => import('./pages/auth/recover/recover.module').then(m => m.RecoverModule)
      }
    ]
  },
  {
    path: '',
    component: AdminComponent,
    children: [
      {
        path: 'usuarios',
        loadChildren: () => import('./catalogos/usuarios/usuarios.module').then(m => m.UsuariosModule)
      }, {
        path: 'dashboard',
        loadChildren: () => import('./pages/dashboard/dashboard-default/dashboard-default.module').then(m => m.DashboardDefaultModule)
      }, {
        path: 'user/:id',
        loadChildren: () => import('./catalogos/usuarios/profile/profile.module').then(m => m.ProfileModule)
      }, {
        path: 'nuevo',
        loadChildren: () => import('./catalogos/usuarios/nuevo/nuevo.module').then(m => m.NuevoModule)
      }, {
        path: 'roles',
        loadChildren: () => import('./catalogos/roles/roles.module').then(m => m.RolesModule)
      }, {
        path: 'nuevorol',
        loadChildren: () => import('./catalogos/roles/nuevorol/nuevorol.module').then(m => m.NuevorolModule)
      }, {
        path: 'editrol/:id',
        loadChildren: () => import('./catalogos/roles/editrol/editrol.module').then(m => m.EditrolModule)
      }, {
        path: 'clientes',
        loadChildren: () => import('./catalogos/clientes/clientes.module').then(m => m.ClientesModule)
      }, {
        path: 'nuevocliente',
        loadChildren: () => import('./catalogos/clientes/nuevocliente/nuevocliente.module').then(m => m.NuevoclienteModule)
      }, {
        path: 'editcliente/:id',
        loadChildren: () => import('./catalogos/clientes/editcliente/editcliente.module').then(m => m.EditclienteModule)
      }, {
        path: 'direcciones',
        loadChildren: () => import('./catalogos/direcciones/direcciones.module').then(m => m.DireccionesModule)
      }, {
        path: 'nuevodirecciones/:idcliente/:cliente',
        loadChildren: () => import('./catalogos/direcciones/nuevodirecciones/nuevodirecciones.module').then(m => m.NuevodireccionesModule)
      }, {
        path: 'editdirecciones/:id/:cliente',
        loadChildren: () => import('./catalogos/direcciones/editdirecciones/editdirecciones.module').then(m => m.EditdireccionesModule)
      }, {
        path: 'fiscal',
        loadChildren: () => import('./catalogos/fiscal/fiscal.module').then(m => m.FiscalModule)
      }, {
        path: 'nuevofiscal/:idcliente/:tel/:cliente',
        loadChildren: () => import('./catalogos/fiscal/nuevofiscal/nuevofiscal.module').then(m => m.NuevofiscalModule)
      }, {
        path: 'editfiscal/:id/:cliente',
        loadChildren: () => import('./catalogos/fiscal/editfiscal/editfiscal.module').then(m => m.EditfiscalModule)
      }, {
        path: 'sucursales',
        loadChildren: () => import('./catalogos/sucursales/sucursales.module').then(m => m.SucursalesModule)
      }, {
        path: 'nuevosucursal',
        loadChildren: () => import('./catalogos/sucursales/nuevosucursal/nuevosucursal.module').then(m => m.NuevosucursalModule)
      }, {
        path: 'editsucursal/:id',
        loadChildren: () => import('./catalogos/sucursales/editsucursal/editsucursal.module').then(m => m.EditsucursalModule)
      }, {
        path: 'listas',
        loadChildren: () => import('./catalogos/listas/listas.module').then(m => m.ListasModule)
      }, {
        path: 'impresion',
        loadChildren: () => import('./acciones/documentaciones/docchofer/docchofer.module').then(m => m.DocchoferModule)
      }, {
        path: 'documentaciones',
        loadChildren: () => import('./acciones/documentaciones/documentaciones.module').then(m => m.DocumentacionesModule)
      }, {
        path: 'rastreos',
        loadChildren: () => import('./pages/rastreos/rastreos.module').then(m => m.RastreosModule)
      }, {
        path: 'nuevadoc',
        loadChildren: () => import('./acciones/documentaciones/nuevadoc/nuevadoc.module').then(m => m.NuevadocModule)
      }, {
        path: 'editdoc/:id/:accion',
        loadChildren: () => import('./acciones/documentaciones/editdoc/editdoc.module').then(m => m.EditdocModule)
      }, {
        path: 'turnos',
        loadChildren: () => import('./acciones/turnos/turnos.module').then(m => m.TurnosModule)
      }, {
        path: 'productos',
        loadChildren: () => import('./catalogos/productos/productos.module').then(m => m.ProductosModule)
      }, {
        path: 'nuevoproducto',
        loadChildren: () => import('./catalogos/productos/nuevoproducto/nuevoproducto.module').then(m => m.NuevoproductoModule)
      }, {
        path: 'editproducto/:id',
        loadChildren: () => import('./catalogos/productos/editproducto/editproducto.module').then(m => m.EditproductoModule)
      }, {
        path: 'contactos',
        loadChildren: () => import('./catalogos/contactos/contactos.module').then(m => m.ContactosModule)
      }, {
        path: 'nuevocontacto',
        loadChildren: () => import('./catalogos/contactos/nuevocontacto/nuevocontacto.module').then(m => m.NuevocontactoModule)
      }, {
        path: 'editcontacto/:id',
        loadChildren: () => import('./catalogos/contactos/editcontacto/editcontacto.module').then(m => m.EditcontactoModule)
      }, {
        path: 'prodespeciales',
        loadChildren: () => import('./catalogos/prodespeciales/prodespeciales.module').then(m => m.ProdespecialesModule)
      }, {
        path: 'nuevope',
        loadChildren: () => import('./catalogos/prodespeciales/nuevope/nuevope.module').then(m => m.NuevopeModule)
      }, {
        path: 'editpe/:id',
        loadChildren: () => import('./catalogos/prodespeciales/editpe/editpe.module').then(m => m.EditpeModule)
      }, {
        path: 'camiones',
        loadChildren: () => import('./catalogos/camiones/camiones.module').then(m => m.CamionesModule)
      }, {
        path: 'reportes',
        loadChildren: () => import('./pages/reportes/reportes.module').then(m => m.ReportesModule)
      }, {
        path: 'nuevocamion',
        loadChildren: () => import('./catalogos/camiones/nuevocamion/nuevocamion.module').then(m => m.NuevocamionModule)
      }, {
        path: 'editcamion/:id',
        loadChildren: () => import('./catalogos/camiones/editcamion/editcamion.module').then(m => m.EditcamionModule)
      }, {
        path: 'chofer',
        loadChildren: () => import('./catalogos/chofer/chofer.module').then(m => m.ChoferModule)
      }, {
        path: 'nuevochofer',
        loadChildren: () => import('./catalogos/chofer/nuevochofer/nuevochofer.module').then(m => m.NuevochoferModule)
      }, {
        path: 'editchofer/:id',
        loadChildren: () => import('./catalogos/chofer/editchofer/editchofer.module').then(m => m.EditchoferModule)
      }, {
        path: 'embarque',
        loadChildren: () => import('./acciones/embarque/embarque.module').then(m => m.EmbarqueModule)
      }, {
        path: 'nuevoembarque',
        loadChildren: () => import('./acciones/embarque/nuevoembarque/nuevoembarque.module').then(m => m.NuevoembarqueModule)
      }, {
        path: 'editarembarque/:id',
        loadChildren: () => import('./acciones/embarque/editarembarque/editarembarque.module').then(m => m.EditarembarqueModule)
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
