import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {animate, AUTO_STYLE, state, style, transition, trigger} from '@angular/animations';
import {MenuItems} from '../../shared/menu-items/menu-items';
import {TokenStorageService} from '../../shared/JWT/token-storage.service';
import {Router, ActivatedRoute} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Location} from '@angular/common';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
  animations: [
    trigger('mobileMenuTop', [
      state('no-block, void',
        style({
          overflow: 'hidden',
          height: '0px',
        })
      ),
      state('yes-block',
        style({
          height: AUTO_STYLE,
        })
      ),
      transition('no-block <=> yes-block', [
        animate('400ms ease-in-out')
      ])
    ]),
    trigger('slideInOut', [
      state('in', style({
        transform: 'translate3d(0, 0, 0)'
      })),
      state('out', style({
        transform: 'translate3d(100%, 0, 0)'
      })),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out'))
    ]),
    trigger('slideOnOff', [
      state('on', style({
        transform: 'translate3d(0, 0, 0)'
      })),
      state('off', style({
        transform: 'translate3d(100%, 0, 0)'
      })),
      transition('on => off', animate('400ms ease-in-out')),
      transition('off => on', animate('400ms ease-in-out'))
    ]),
    trigger('fadeInOutTranslate', [
      transition(':enter', [
        style({opacity: 0}),
        animate('400ms ease-in-out', style({opacity: 1}))
      ]),
      transition(':leave', [
        style({transform: 'translate(0)'}),
        animate('400ms ease-in-out', style({opacity: 0}))
      ])
    ])
  ]
})
export class AdminComponent implements OnInit {
  user: any;
  Sucursales: any;
  sucursal: any;
  idSucursal = 0;
  Usermenu: any;
  Permisos: any;
  MenuIcons: any = [{
    'Usuarios': 'fa fa-user',
    'Roles': 'ti-panel',
    'Clientes': 'fa fa-user',
    'Dashboard': 'fa fa-home',
    'Sucursales': 'fa fa-building',
    'Listas de Selección': 'fa fa-list',
    'Documentaciones': 'fa fa-barcode',
    'Rastreos': 'fa fa-map-marker',
    'Turnos': 'fa fa-clock-o',
    'Productos': 'fa fa-dropbox',
    'Serv. Adicionales': 'fa fa-asterisk',
    'Contactos': 'fa fa-user',
    'Camiones': 'fa fa-truck',
    'Choferes': 'fa fa-user',
    'Embarque': 'fa fa-cubes',
    'Reportes': 'fa fa-file-pdf-o'
  }];
  MenuLinks: any = [{
    'Usuarios': 'usuarios',
    'Roles': 'roles',
    'Clientes': 'clientes',
    'Dashboard': 'dashboard',
    'Listas de Selección': 'listas',
    'Documentaciones': 'documentaciones',
    'Rastreos': 'rastreos',
    'Turnos': 'turnos',
    'Productos': 'productos',
    'Serv. Adicionales': 'prodespeciales',
    'Contactos': 'contactos',
    'Camiones': 'camiones',
    'Choferes': 'chofer',
    'Embarque': 'embarque',
    'Sucursales': 'sucursales',
    'Reportes': 'reportes'
  }];
  navType: string; /* st1, st2(default), st3, st4 */
  themeLayout: string; /* vertical(default) */
  layoutType: string; /* dark, light */
  verticalPlacement: string; /* left(default), right */
  verticalLayout: string; /* wide(default), box */
  deviceType: string; /* desktop(default), tablet, mobile */
  verticalNavType: string; /* expanded(default), offcanvas */
  verticalEffect: string; /* shrink(default), push, overlay */
  vNavigationView: string; /* view1(default) */
  pcodedHeaderPosition: string; /* fixed(default), relative*/
  pcodedSidebarPosition: string; /* fixed(default), absolute*/
  headerTheme: string; /* theme1(default), theme2, theme3, theme4, theme5, theme6 */
  logoTheme: string; /* theme1(default), theme2, theme3, theme4, theme5, theme6 */

  innerHeight: string;
  windowWidth: number;

  toggleOn: boolean;

  headerFixedMargin: string;
  navBarTheme: string; /* theme1, themelight1(default)*/
  activeItemTheme: string; /* theme1, theme2, theme3, theme4(default), ..., theme11, theme12 */

  isCollapsedMobile: string;
  isCollapsedSideBar: string;

  chatToggle: string;
  chatToggleInverse: string;
  chatInnerToggle: string;
  chatInnerToggleInverse: string;

  menuTitleTheme: string; /* theme1, theme2, theme3, theme4, theme5(default), theme6 */
  itemBorder: boolean;
  itemBorderStyle: string; /* none(default), solid, dotted, dashed */
  subItemBorder: boolean;
  subItemIcon: string; /* style1, style2, style3, style4, style5, style6(default) */
  dropDownIcon: string; /* style1(default), style2, style3 */
  configOpenRightBar: string;
  isSidebarChecked: boolean;
  isHeaderChecked: boolean;
  menutest: any;
  etiqueta: any;
  @ViewChild('searchFriends', /* TODO: add static flag */ {static: false}) search_friends: ElementRef;
  notadmin: boolean = true;

  public config: any;

  constructor(public location: Location, private actrout: ActivatedRoute, private http: HttpClient, private router: Router, public menuItems: MenuItems, private tokenStorage: TokenStorageService) {
    this.user = this.tokenStorage.getUser();
    this.notadmin = false;
    this.Sucursales = [];
    this.sucursal = '';

    this.navType = 'st5';
    this.themeLayout = 'vertical';
    this.vNavigationView = 'view1';
    this.verticalPlacement = 'left';
    this.verticalLayout = 'wide';
    this.deviceType = 'desktop';
    this.verticalNavType = 'expanded';
    this.verticalEffect = 'shrink';
    this.pcodedHeaderPosition = 'fixed';
    this.pcodedSidebarPosition = 'fixed';
    this.headerTheme = 'theme1';
    this.logoTheme = 'theme1';

    this.toggleOn = true;

    this.headerFixedMargin = '80px';
    this.navBarTheme = 'themelight1';
    this.activeItemTheme = 'theme4';

    this.isCollapsedMobile = 'no-block';
    this.isCollapsedSideBar = 'no-block';

    this.chatToggle = 'out';
    this.chatToggleInverse = 'in';
    this.chatInnerToggle = 'off';
    this.chatInnerToggleInverse = 'on';

    this.menuTitleTheme = 'theme5';
    this.itemBorder = true;
    this.itemBorderStyle = 'none';
    this.subItemBorder = true;
    this.subItemIcon = 'style6';
    this.dropDownIcon = 'style1';
    this.isSidebarChecked = true;
    this.isHeaderChecked = true;
    const scrollHeight = window.screen.height - 150;
    this.innerHeight = scrollHeight + 'px';
    this.windowWidth = window.innerWidth;
    this.setMenuAttributes(this.windowWidth);
    let hijos: any;
    this.Permisos = [];
    this.menuItems.clear();

    if (this.user['sAdmin']) {
      this.notadmin = true;
      this.menutest =
        {
          label: 'Configuraciones',
          main: [
            {
              state: 'usuarios',
              short_label: 'U',
              name: 'Usuarios',
              type: 'link',
              icon: this.MenuIcons[0]['Usuarios']
            },
            {
              state: 'roles',
              short_label: 'R',
              name: 'Roles',
              type: 'link',
              icon: this.MenuIcons[0]['Roles']
            },
            {
              state: 'listas',
              short_label: 'L',
              name: 'Listas de Selección',
              type: 'link',
              icon: this.MenuIcons[0]['Listas de Selección']
            }
          ],
        };

      this.menuItems.add(this.menutest);
      this.menutest =
        {
          label: 'Navegación',
          main: [
            {
              state: 'dashboard',
              short_label: 'D',
              name: 'Dashboard',
              type: 'link',
              icon: 'fa fa-home'
            },
          ],
        };

      this.menuItems.add(this.menutest);
      this.menutest =
        {
          label: 'Catálogos',
          main: [
            {
              state: 'clientes',
              short_label: 'C',
              name: 'Clientes',
              type: 'link',
              icon: 'fa fa-users'
            }, {
              state: 'contactos',
              short_label: 'C',
              name: 'Contactos',
              type: 'link',
              icon: this.MenuIcons[0]['Contactos']
            }, {
              state: 'sucursales',
              short_label: 'S',
              name: 'Sucursales',
              type: 'link',
              icon: 'fa fa-building'
            }, {
              state: 'productos',
              short_label: 'P',
              name: 'Productos',
              type: 'link',
              icon: this.MenuIcons[0]['Productos']
            }, {
              state: 'prodespeciales',
              short_label: 'S',
              name: 'Serv. Adicionales',
              type: 'link',
              icon: this.MenuIcons[0]['Serv. Adicionales']
            }, {
              state: 'camiones',
              short_label: 'C',
              name: 'Camiones',
              type: 'link',
              icon: this.MenuIcons[0]['Camiones']
            }, {
              state: 'chofer',
              short_label: 'C',
              name: 'Choferes',
              type: 'link',
              icon: this.MenuIcons[0]['Choferes']
            }
          ],
        };
      this.menuItems.add(this.menutest);
      this.menutest =
        {
          label: 'Acciones',
          main: [
            {
              state: 'documentaciones',
              short_label: 'D',
              name: 'Documentaciones',
              type: 'link',
              icon: this.MenuIcons[0]['Documentaciones']
            },
            {
              state: 'rastreos',
              short_label: 'RA',
              name: 'Rastreos',
              type: 'link',
              icon: this.MenuIcons[0]['Rastreos']
            },
            {
              state: 'embarque',
              short_label: 'E',
              name: 'Embarque',
              type: 'link',
              icon: this.MenuIcons[0]['Embarque']
            },
            {
              state: 'turnos',
              short_label: 'T',
              name: 'Turnos',
              type: 'link',
              icon: this.MenuIcons[0]['Turnos']
            },
          ],
        };
      this.menuItems.add(this.menutest);
      this.menutest =
        {
          label: 'Reportes',
          main: [
            {
              state: 'reportes',
              short_label: 'R',
              name: 'Reportes',
              type: 'link',
              icon: 'fa fa-file'
            },
          ],
        };
      this.menuItems.add(this.menutest);
    } else {
      if (this.user['rol'] != 1) {
        this.notadmin = false;
      } else {
        this.notadmin = true;
      }
      this.http.get(this.tokenStorage.getapi() + 'MenuRol/ByRol?rol=' + this.user['rol']).subscribe(data2 => {
        data2['datos'] = data2;
        hijos = [];
        for (let x = 0; x < data2['datos'].length; x++) {
          if (data2['datos'][x]['catalogos']['nombre'] != 'Direcciones' && data2['datos'][x]['catalogos']['nombre'] != 'Datos Fiscales') {
            if (this.etiqueta == undefined) {
              this.etiqueta = data2['datos'][x]['etiquetas']['nombre'];
            }
            if (this.etiqueta != data2['datos'][x]['etiquetas']['nombre']) {
              if (hijos.length > 0) {
                this.menutest = {
                  label: this.etiqueta,
                  main: hijos
                };
                this.menuItems.add(this.menutest);
                hijos = [];
              }
              this.etiqueta = data2['datos'][x]['etiquetas']['nombre'];
            }
            if (data2['datos'][x]['menupermisos'][0]['listar']) {
              hijos.push({
                state: this.MenuLinks[0][data2['datos'][x]['catalogos']['nombre']],
                short_label: data2['datos'][x]['catalogos']['nombre'].substr(0, 1).toUpperCase(),
                name: data2['datos'][x]['catalogos']['nombre'],
                type: 'link',
                icon: this.MenuIcons[0][data2['datos'][x]['catalogos']['nombre']]
              });
            }
          }
        }
        if (hijos.length > 0) {
          this.menutest = {
            label: this.etiqueta,
            main: hijos
          };
          this.menuItems.add(this.menutest);
        }
      }, error => {
        if (error['status'] == '401') {
          this.router.navigate(['/']);
        }
      });
    }
    this.http.get(this.tokenStorage.getapi() + 'Sucursales?Type=ACTIVE').subscribe(data => {

      if (!data['IsError']) {
        this.Sucursales.push({
          id: '0',
          text: 'Todas'
        });
        if (this.tokenStorage.getSuc() != '' || this.tokenStorage.getSuc() != null) {
          this.idSucursal = parseInt(this.tokenStorage.getSuc());
          for (let i = 0; i < this.Sucursales.length; i++) {
            if (this.tokenStorage.getSuc() == this.Sucursales[i]['id']) {
              this.sucursal = this.Sucursales[i]['text'];
              this.tokenStorage.saveSuc(this.Sucursales[i]['id']);
              break;
            }
          }
        } else {
          this.sucursal = 'Todas';
          this.tokenStorage.saveSuc('0');
        }
        for (let i = 0; i < data['Data'].length; i++) {
          this.Sucursales.push({
            id: data['Data'][i]['id'],
            text: data['Data'][i]['nombre'],
          });
        }
        if (this.tokenStorage.getSuc() != '' || this.tokenStorage.getSuc() != null) {
          for (let i = 0; i < this.Sucursales.length; i++) {
            if (this.tokenStorage.getSuc() == this.Sucursales[i]['id']) {
              this.sucursal = this.Sucursales[i]['text'];
              this.tokenStorage.saveSuc(this.Sucursales[i]['id']);
              break;
            }
          }
        } else {
          this.sucursal = 'Todas';
          this.tokenStorage.saveSuc('0');
        }
      }

    }, error => {
      if (error['status'] == '401') {
        this.router.navigate(['/']);
      }
    });
    // dark
    /*this.setLayoutType('dark');
    this.headerTheme = 'theme5';
    this.logoTheme = 'theme5';*/

    // light-dark
    /*this.setLayoutType('dark');
    this.setNavBarTheme('themelight1');
    this.navType = 'st2';*/

    // dark-light
    // this.setNavBarTheme('theme1');
    // this.navType = 'st3';

  }

  ngOnInit() {
    this.setBackgroundPattern('pattern2');
  }

  onResize(event) {
    this.innerHeight = event.target.innerHeight + 'px';
    /* menu responsive */
    this.windowWidth = event.target.innerWidth;
    let reSizeFlag = true;
    if (this.deviceType === 'tablet' && this.windowWidth >= 768 && this.windowWidth <= 1024) {
      reSizeFlag = false;
    } else if (this.deviceType === 'mobile' && this.windowWidth < 768) {
      reSizeFlag = false;
    }
    /* for check device */
    if (reSizeFlag) {
      this.setMenuAttributes(this.windowWidth);
    }
  }

  setMenuAttributes(windowWidth) {
    if (windowWidth >= 768 && windowWidth <= 1024) {
      this.deviceType = 'tablet';
      this.verticalNavType = 'offcanvas';
      this.verticalEffect = 'push';
    } else if (windowWidth < 768) {
      this.deviceType = 'mobile';
      this.verticalNavType = 'offcanvas';
      this.verticalEffect = 'overlay';
    } else {
      this.deviceType = 'desktop';
      this.verticalNavType = 'expanded';
      this.verticalEffect = 'shrink';
    }
  }

  toggleOpened() {
    if (this.windowWidth < 768) {
      this.toggleOn = this.verticalNavType === 'offcanvas' ? true : this.toggleOn;
    }
    this.verticalNavType = this.verticalNavType === 'expanded' ? 'offcanvas' : 'expanded';
  }

  onClickedOutside(e: Event) {
    if (this.windowWidth < 768 && this.toggleOn && this.verticalNavType !== 'offcanvas') {
      this.toggleOn = true;
      this.verticalNavType = 'offcanvas';
    }
  }

  onMobileMenu() {
    this.isCollapsedMobile = this.isCollapsedMobile === 'yes-block' ? 'no-block' : 'yes-block';
  }

  toggleChat() {
    this.chatToggle = this.chatToggle === 'out' ? 'in' : 'out';
    this.chatToggleInverse = this.chatToggleInverse === 'out' ? 'in' : 'out';
    this.chatInnerToggle = 'off';
    this.chatInnerToggleInverse = 'off';
  }

  toggleChatInner() {
    this.chatInnerToggle = this.chatInnerToggle === 'off' ? 'on' : 'off';
    this.chatInnerToggleInverse = this.chatInnerToggleInverse === 'off' ? 'on' : 'off';
  }

  searchFriendList(e: Event) {
    const search = (this.search_friends.nativeElement.value).toLowerCase();
    let search_input: string;
    let search_parent: any;
    const friendList = document.querySelectorAll('.userlist-box .media-body .chat-header');
    Array.prototype.forEach.call(friendList, function (elements, index) {
      search_input = (elements.innerHTML).toLowerCase();
      search_parent = (elements.parentNode).parentNode;
      if (search_input.indexOf(search) !== -1) {
        search_parent.classList.add('show');
        search_parent.classList.remove('hide');
      } else {
        search_parent.classList.add('hide');
        search_parent.classList.remove('show');
      }
    });
  }

  toggleOpenedSidebar() {
    this.isCollapsedSideBar = this.isCollapsedSideBar === 'yes-block' ? 'no-block' : 'yes-block';
  }

  toggleRightbar() {
    this.configOpenRightBar = this.configOpenRightBar === 'open' ? '' : 'open';
  }

  setSidebarPosition() {
    this.isSidebarChecked = !this.isSidebarChecked;
    this.pcodedSidebarPosition = this.isSidebarChecked === true ? 'fixed' : 'absolute';
  }

  setHeaderPosition() {
    this.isHeaderChecked = !this.isHeaderChecked;
    this.pcodedHeaderPosition = this.isHeaderChecked === true ? 'fixed' : 'relative';
    this.headerFixedMargin = this.isHeaderChecked === true ? '80px' : '';
  }

  setBackgroundPattern(pattern) {
    document.querySelector('body').setAttribute('themebg-pattern', pattern);
  }

  setLayoutType(type: string) {
    this.layoutType = type;
    if (type === 'dark') {
      this.headerTheme = 'theme6';
      this.navBarTheme = 'theme1';
      this.logoTheme = 'theme6';
      document.querySelector('body').classList.add('dark');
    } else {
      this.headerTheme = 'theme1';
      this.navBarTheme = 'themelight1';
      this.logoTheme = 'theme1';
      document.querySelector('body').classList.remove('dark');
    }
  }

  setNavBarTheme(theme: string) {
    if (theme === 'themelight1') {
      this.navBarTheme = 'themelight1';
    } else {
      this.navBarTheme = 'theme1';
    }
  }

  selectSuc() {
    const sucursal = this.Sucursales.find(aux => aux.id == this.idSucursal);
    this.tokenStorage.saveSuc(sucursal.id);
    this.sucursal = sucursal.text;
    window.location.reload();
  }

}
